
const getEmojisUrl = 'https://api.retool.com/v1/workflows/92c8a3c6-4341-4404-8168-6993c5a8a5d9/startTrigger?workflowApiKey=retool_wk_7826cf9c26d2484d84031b81171b95cf';

const requestOptions = {
  method: 'POST',
};

let emojis = {};

fetch(getEmojisUrl)
  .then(response => response.json()) 
  .then(data => {
    emojis = data
  
    console.log(emojis)
    emojis.forEach((emojiObj) => {
        const emojiContainer = document.querySelector(".emoji-container");
        const emojiCard = document.createElement("div");
        emojiCard.className = "emoji-card";
        
        const emoji = document.createElement("div");
        emoji.className = "emoji";
        emoji.textContent = emojiObj.emoji;
        
        const name = document.createElement("div");
        name.className = "name";
        name.textContent = emojiObj.name;
        
        const link = document.createElement("a");
        link.className = "link";
        link.textContent = "visit url";
        link.href = emojiObj.url;
        link.target = "_blank"; // Open link in a new tab
        
        emojiCard.appendChild(emoji);
        emojiCard.appendChild(name);
        emojiCard.appendChild(link);
        
        emojiContainer.appendChild(emojiCard);
      })
      var loader = document.getElementById("loader");
      loader.classList.add('hide');
      var element = document.getElementById("bottom-div");
      element.classList.remove("hide-bottom-div");

  })
  .catch(error => {
    console.error('Error:', error);
  });

 
  window.handleButtonClick = function handleButtonClick() {
    var emailInput = document.getElementById("email");
    var submitButton = document.getElementById("submitButton");
    submitButton.textContent = "Sending...";
    var emailValue = emailInput.value;
    console.log("Email value:", emailValue);

    const sendApiDocsUrl = 'https://api.retool.com/v1/workflows/29dbced8-8aaf-4e27-9b87-71b4f7a5d9fb/startTrigger?workflowApiKey=retool_wk_f434eb07710440f2bec9a5a251648687';

    const requestData = {
        email: emailValue,
    };

    const requestOptions = {
    method: 'POST', 
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestData) 
    };


    let message = ''

    fetch(sendApiDocsUrl, requestOptions)
    .then(response => response.json())
    .then(data => {
        message = data.message;
        submitButton.textContent = 'Get Secret Key'
        const toast = document.getElementById('toast');
        toast.textContent = message
        toast.classList.add('visible');
            setTimeout(() => {
            toast.classList.remove('visible');
        }, 5000);
        
    })
    .catch(error => {
        console.error('Error:', error);

    });

  }



